import instance from "./axios-instance";

const URL = process.env.REACT_APP_API_URL;

const service = {
  async registerMod(user, token) {
    try {
      const response = await instance.post("/moderator", user, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response;
    } catch (err) {
      throw err.response.data;
    }
  },

  async login(req) {
    try {
      const response = await instance.post("/login", req);
      return response;
    } catch (err) {
      throw err.response.data;
    }
  },

  async updatePost(updatedPost, token) {
    try {
      const { id, title, content, innerHTML, pureText } = updatedPost;
      const postData = { title, content, innerHTML, pureText };

      const response = await instance.put(`/post/${id}`, postData, {
        headers: { Authorization: `Bearer ${token}` },
      });

      return response;
    } catch (err) {
      console.error("Error updating post:", err);
      throw err.response.data;
    }
  },

  async getAllPosts() {
    try {
      const response = await instance.get("/posts");
      return response;
    } catch (err) {
      throw err;
    }
  },

  async getAllEditions() {
    try {
      const response = await instance.get("/editions");
      return response;
    } catch (err) {
      throw err;
    }
  },
  async getEditionById(id) {
    try {
      var allEditions = await this.getAllEditions();
      allEditions = allEditions.data;
      const edition = allEditions.find((edition) => edition.id === Number(id));
      if (!edition) {
        throw new Error("Edition not found");
      }

      return edition;
    } catch (err) {
      throw err;
    }
  },
  async updateEdition(edition, token) {
    try {
      const response = await instance.put(
        `/edition/${edition.id}`,
        {
          title: edition.title,
          year: edition.year,
          content: edition.content,
          innerHTML: edition.innerHTML,
          pureText: edition.pureText,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response;
    } catch (err) {
      console.error("Error updating edition:", err);
      throw err.response?.data || "Erro desconhecido ao atualizar edição";
    }
  },

  async deleteEdition(editionId, token) {
    try {
      const response = await instance.delete(`/edition/${editionId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      return response;
    } catch (err) {
      console.error("Error deleting edition:", err);
      throw err.response.data;
    }
  },

  async getAllUsers(token) {
    try {
      const response = await instance.get("/users", {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response;
    } catch (err) {
      throw err;
    }
  },

  async updateUser(userId, userData, token) {
    try {
      const response = await instance.put(
        `/user/${userId}`,
        { username: userData.username, roleId: userData.roleId },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response;
    } catch (err) {
      console.error("Error updating user:", err);
      throw err.response.data;
    }
  },

  async deleteUser(userId, token) {
    try {
      const response = await instance.delete(`/user/${userId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      return response;
    } catch (err) {
      console.error("Error deleting user:", err);
      throw err.response.data;
    }
  },

  async recoverPassword(email) {
    try {
      const response = await instance.post("/recoverPassword", email);
      return response;
    } catch (err) {
      throw err.response.data;
    }
  },

  async uploadPhoto(editionId, formData, token) {
    try {
      const response = await fetch(`${URL}/upload/${editionId}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
      if (!response.ok) {
        throw new Error("Error uploading photo");
      }
      return await response.json();
    } catch (error) {
      console.error("Error uploading photo:", error);
      throw error;
    }
  },

  async searchPost(term) {
    try {
      const response = await instance.post(`/posts/search`, { term }); // Muda de GET para POST e envia no corpo
      return response;
    } catch (err) {
      console.error("Error searching for posts:", err);
      throw err.response?.data || "Erro desconhecido ao buscar posts";
    }
  },

  async deletePhoto(photoId, token) {
    try {
      const response = await fetch(`${URL}/photo/${photoId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Error deleting photo");
      }
      return await response.json();
    } catch (error) {
      console.error("Error deleting photo:", error);
      throw error;
    }
  },

  getPhotoUrl(photoId) {
    return `${URL}/photo/${photoId}`;
  },
};

export default service;
