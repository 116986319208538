import React, { useState, useEffect, useRef, useCallback, memo } from "react";
import "./vertical.scss";
import filme from "./Assets/filme.png";

// Componente LazyImage com Intersection Observer
const LazyImage = ({ src, alt, onClick, className, style }) => {
  const [isVisible, setIsVisible] = useState(false);
  const imgRef = useRef();

  useEffect(() => {
    let observer;
    if (imgRef.current) {
      observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setIsVisible(true);
              observer.disconnect();
            }
          });
        },
        { threshold: 0.1 }
      );
      observer.observe(imgRef.current);
    }
    return () => {
      if (observer && observer.disconnect) observer.disconnect();
    };
  }, []);

  return (
    <img
      ref={imgRef}
      src={isVisible ? src : undefined}
      alt={alt}
      onClick={onClick}
      className={className}
      style={style}
      loading="lazy"
    />
  );
};

// Memoização do componente ImageItem
const ImageItem = memo(({ imageObj, title, onClick, style, isSuperior, imageIndex }) => (
  <>
    <div
      className={`titulo-${isSuperior ? "superior" : "inferior"}`}
      style={{ left: calculateImagePosition(imageIndex) }}
    >
      {title}
    </div>
    <LazyImage
      src={imageObj.small}
      alt={title}
      onClick={onClick}
      className="imagem-posicionada-edition lazy-image"
      style={{ left: calculateImagePosition(imageIndex) }}
    />
  </>
));

const calculateImagePosition = (index) => {
  const positions = ["0.9%", "25.6%", "50.4%", "75.2%"];
  return positions[index] || "0%";
};

const Edition = ({
  edition,
  images,
  description,
  signatures,
  onBack,
  showBackButton,
  titles = [],
}) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [sliderIndex, setSliderIndex] = useState(0);
  const [showFullInfo, setShowFullInfo] = useState(false);
  const [showFullGallery, setShowFullGallery] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [hoverDuration, setHoverDuration] = useState(0);
  const hoverTimerRef = useRef(null);

  const imagesPerSlide = 4;
  const groupedImages = [];
  for (let i = 0; i < images.length; i += imagesPerSlide) {
    groupedImages.push(images.slice(i, i + imagesPerSlide));
  }

  const totalSlides = groupedImages.length;

  const nextSlide = useCallback(
    () => setSliderIndex((prevIndex) => (prevIndex + 1) % totalSlides),
    [totalSlides]
  );

  const prevSlide = useCallback(
    () => setSliderIndex((prevIndex) => (prevIndex - 1 + totalSlides) % totalSlides),
    [totalSlides]
  );

  // Efeito para lidar com o intervalo baseado no estado de hover
  useEffect(() => {
    let interval;

    if (!isHovered) {
      if (hoverDuration >= 5000) {
        // Se passou mais de 5 segundos em hover, o próximo slide acontece 1s após o mouse sair
        interval = setTimeout(nextSlide, 1000);
      } else {
        // Caso contrário, continua o slideshow normal a cada 5 segundos
        interval = setInterval(nextSlide, 5000);
      }
    }
    return () => {
      clearInterval(interval);
      clearTimeout(interval);
    };
  }, [isHovered, hoverDuration, nextSlide]);

  // Quando o mouse entra no slider, começa a contar o tempo de hover
  const handleMouseEnter = () => {
    setIsHovered(true);
    hoverTimerRef.current = setInterval(() => {
      setHoverDuration((prevDuration) => prevDuration + 1000);
    }, 1000); // Incrementa o tempo de hover a cada segundo
  };

  // Quando o mouse sai do slider, para de contar o tempo de hover e reseta se for menos de 5 segundos
  const handleMouseLeave = () => {
    setIsHovered(false);
    clearInterval(hoverTimerRef.current);

    if (hoverDuration < 5000) {
      setHoverDuration(0); // Reseta a duração se o hover foi menor que 5 segundos
    }
  };

  const openImageCarousel = useCallback(
    (index) => {
      setSelectedImageIndex(index);
      setSelectedImage(images[index].large);

      // Pré-carrega as imagens adjacentes
      const nextIndex = (index + 1) % images.length;
      const prevIndex = (index - 1 + images.length) % images.length;

      const preloadNextImage = new Image();
      preloadNextImage.src = images[nextIndex].large;

      const preloadPrevImage = new Image();
      preloadPrevImage.src = images[prevIndex].large;
    },
    [images]
  );

  const nextImage = useCallback(() => {
    setSelectedImageIndex((prevIndex) => {
      const newIndex = (prevIndex + 1) % images.length;
      setSelectedImage(images[newIndex].large);

      // Pré-carrega a próxima imagem
      const nextNextIndex = (newIndex + 1) % images.length;
      const preloadNextImage = new Image();
      preloadNextImage.src = images[nextNextIndex].large;

      return newIndex;
    });
  }, [images]);

  const previousImage = useCallback(() => {
    setSelectedImageIndex((prevIndex) => {
      const newIndex = (prevIndex - 1 + images.length) % images.length;
      setSelectedImage(images[newIndex].large);

      // Pré-carrega a imagem anterior
      const prevPrevIndex = (newIndex - 1 + images.length) % images.length;
      const preloadPrevImage = new Image();
      preloadPrevImage.src = images[prevPrevIndex].large;

      return newIndex;
    });
  }, [images]);

  const toggleFullInfo = () => setShowFullInfo(!showFullInfo);
  const toggleFullGallery = () => setShowFullGallery(!showFullGallery);
  const closeModal = () => {
    setSelectedImage(null);
    setSelectedImageIndex(null);
  };

  const editionYears = {
    0: " ",
    1: "2008",
    2: "2009",
    3: "2010",
    4: "2012",
    5: "2013",
    6: "2014",
    7: "2017",
    8: "2020",
  };

  return (
    <div className="edition-container">
      <div className="vertical-title bg-azul">
        <h3>
          {edition}º Edição - {editionYears[edition]}
        </h3>
      </div>

      {!showFullGallery && (
        <>
          <div
            className="slider-wrapper"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div
              className="slider-container"
              style={{ transform: `translateX(-${sliderIndex * 100}%)` }}
            >
              {groupedImages.map((group, slideIndex) => (
                <div className="filme-container" key={slideIndex}>
                  {group.map((imageObj, imageIndex) => {
                    const globalImageIndex =
                      imageIndex + slideIndex * imagesPerSlide;
                    const title =
                      titles[globalImageIndex] ||
                      `Imagem ${globalImageIndex + 1}`;
                    const isSuperior = globalImageIndex % 2 === 0;

                    return (
                      <ImageItem
                        key={globalImageIndex}
                        imageObj={imageObj}
                        title={title}
                        onClick={() => openImageCarousel(globalImageIndex)}
                        isSuperior={isSuperior}
                        imageIndex={imageIndex}
                      />
                    );
                  })}
                  <img
                    src={filme}
                    alt="Fundo do filme"
                    className="filme-preto"
                    loading="lazy"
                  />
                </div>
              ))}
            </div>

            <button className="slider-button left" onClick={prevSlide}>
              {"<"}
            </button>
            <button className="slider-button right" onClick={nextSlide}>
              {">"}
            </button>
          </div>

          <div className="edition-info-container">
            <div
              className={`edition-info ${
                showFullInfo ? "expanded" : "collapsed"
              }`}
            >
              <p>{description}</p>

              {signatures && (
                <div className="signatures">
                  {signatures.map((sig, index) => (
                    <div className="title-name-container" key={index}>
                      <h3>{sig.title}</h3>
                      <p>{sig.name}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="buttons-container">
              <button onClick={toggleFullInfo} className="toggle-button">
                {showFullInfo ? "Ver menos" : "Ver mais"}
              </button>
              <button onClick={toggleFullGallery} className="toggle-button">
                {showFullGallery
                  ? "Ocultar galeria completa"
                  : "Ver galeria completa"}
              </button>
            </div>
          </div>
        </>
      )}

      {showFullGallery && (
        <div className="gallery-container">
          {groupedImages.map((group, groupIndex) => (
            <div className="filme-container" key={groupIndex}>
              {group.map((imageObj, imageIndex) => {
                const globalImageIndex =
                  imageIndex + groupIndex * imagesPerSlide;
                const title =
                  titles[globalImageIndex] || `Imagem ${globalImageIndex + 1}`;
                const isSuperior = globalImageIndex % 2 === 0;

                return (
                  <ImageItem
                    key={globalImageIndex}
                    imageObj={imageObj}
                    title={title}
                    onClick={() => openImageCarousel(globalImageIndex)}
                    isSuperior={isSuperior}
                    imageIndex={imageIndex}
                  />
                );
              })}
              <img
                src={filme}
                alt="Fundo do filme"
                className="filme-preto"
                loading="lazy"
              />
            </div>
          ))}
          <div className="buttons-container">
            <button onClick={toggleFullGallery} className="toggle-button">
              Ocultar galeria completa
            </button>
          </div>
        </div>
      )}

      {selectedImage && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="modal-close-button" onClick={closeModal}>
              ×
            </button>

            <button
              className="modal-nav-button left"
              onClick={(e) => {
                e.stopPropagation();
                previousImage();
              }}
            >
              {"<"}
            </button>

            <div className="modal-image-title-container">
              <div className="modal-image-container">
                <img
                  src={selectedImage}
                  alt="Imagem ampliada"
                  className="modal-image"
                />
              </div>

              <div className="modal-title-container">
                <h3 className="author-title">
                  {titles[selectedImageIndex]}
                </h3>
              </div>
            </div>

            <button
              className="modal-nav-button right"
              onClick={(e) => {
                e.stopPropagation();
                nextImage();
              }}
            >
              {">"}
            </button>
          </div>
        </div>
      )}

      {showBackButton && (
        <button onClick={onBack} className="back-button">
          Voltar
        </button>
      )}
    </div>
  );
};

export default Edition;
